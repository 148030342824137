import React from "react"
import { graphql } from "gatsby"
import Seo from "src/utils/Seo"
import Breadcrumbs from "src/utils/Breadcrumbs"
import Product from "src/components/Product"

const InternalDoor = ({
  location,
  data: { prismicInternalDoor, prismicOld, prismicNew, allPrismicFaq },
  pageContext,
}) => {
  const { data } = prismicInternalDoor
  const sliderData = prismicInternalDoor.data.configurations
  const mergeCaseStudies = (prismicOld, prismicNew) => {

    const mergedEdges = [...prismicOld.edges, ...prismicNew.edges]
      .sort((a, b) => b.node.first_publication_date - a.node.first_publication_date) // Sort descending (newest first)
      .slice(0, 3); // Pick the most recent 3

    return { edges: mergedEdges }

  };

  const caseStudiesData = mergeCaseStudies(prismicOld, prismicNew)

  const faqData = allPrismicFaq
  const configData = prismicInternalDoor.data.configurations

  const crumbs = [
    { label: "Internal Doors", href: "/internal-doors" },
    { label: "Door Styles", href: "/internal-doors/styles" },
    {
      label: data.title.text,
      href: `/internal-doors/styles/${prismicInternalDoor.uid}`,
      active: true,
    },
  ]

  return (
    <main>
      <Seo
        title={data.meta_title.text ? data.meta_title.text : data.title.text}
        description={
          data.meta_description.text
            ? data.meta_description.text
            : data.description.text
        }
        image={data.meta_image?.url ? data.meta_image.url : data.featured_image?.url}
      />
      <div className="py-5">
        <Breadcrumbs data={crumbs} theme="light" />
      </div>
      <Product
        {...data}
        sliderData={sliderData}
        caseStudiesData={caseStudiesData}
        faqData={faqData}
        configData={configData}
        uid={prismicInternalDoor.uid}
        type={"internal door"}
      />
    </main>
  )
}

export default InternalDoor

export const internalDoorQuery = graphql`
  query InternalDoorBySlug($uid: String!) {
    prismicInternalDoor(uid: { eq: $uid }) {
      uid
      data {
        architects_download {
          url
        }
        title {
          text
        }
        description {
          html
          text
        }
        meta_title {
          text
        }
        meta_description {
          text
        }
        meta_image {
          url
        }
        featured_images {
          image {
            url
            alt
            fluid {
              ...GatsbyPrismicImageFluid
            }
          }
          caption {
            text
          }
        }
        pricing {
          price
        }
        woods_and_finishes {
          wood_or_finish {
            document {
              ... on PrismicWoodsAndFinishes {
                data {
                  title {
                    text
                  }
                  featured_image {
                    url(imgixParams: { auto: "format" })
                  }
                }
              }
            }
          }
          door_image {
            url(imgixParams: { auto: "format" })
          }
        }
        video_link {
          richText
        }
        video_title {
          text
        }
        content {
          richText
        }
        matching_title {
          text
        }
        matching_description {
          richText
        }
        matching_image {
          url
          alt
        }
        matching_link {
          uid
          type
        }
        image_right
        configurations {
          configuration {
            document {
              ... on PrismicConfigurations {
                uid
                id
                data {
                  title {
                    text
                  }
                  slider_desription {
                    richText
                  }
                  slider_image {
                    alt
                    fluid {
                      src
                    }
                  }
                  maximum_size_h
                  maximum_size_w
                  title {
                    text
                  }
                  drawer_image {
                    url
                    alt
                  }
                  description {
                    richText
                  }
                  link {
                    document {
                      ... on PrismicPage {
                        id
                        uid
                      }
                    }
                  }
                }
              }
            }
          }
        }
        specifications {
          specification {
            document {
              ... on PrismicSpecification {
                data {
                  title {
                    text
                  }
                  description {
                    richText
                  }
                }
              }
            }
          }
        }
        specification_sheet {
          url
        }
      }
    }
    prismicOld: allPrismicCaseStudy(
      filter: {
        data: { door_types: { elemMatch: { door_type: { eq: "Internal Door" } } } }
      }
    ) {
      edges {
        node {
          uid
          first_publication_date
          data {
            featured_image {
              alt
              url
              fluid(srcSetBreakpoints: [400, 800]) {
                srcSet
              }
            }
            title {
              text
            }
            description {
              richText
              text
            }
          }
        }
      }
    }
    prismicNew: allPrismicCaseStudyNew(
      filter: {
        data: { door_types: { elemMatch: { door_type: { eq: "Internal Door" } } } }
      }
    ) {
      edges {
        node {
          uid
          first_publication_date
          data {
            featured_image {
              alt
              url
              fluid(srcSetBreakpoints: [400, 800]) {
                srcSet
              }
            }
            title {
              text
            }
            description {
              richText
              text
            }
          }
        }
      }
    }
    allPrismicFaq(
      filter: { data: { category: { eq: "Internal Doors" } } }
      limit: 10
    ) {
      edges {
        node {
          data {
            category
            content {
              richText
            }
            title {
              text
            }
          }
        }
      }
    }
  }
`
